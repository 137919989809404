<template>
  <div class="card card-custom" v-loading="loading">
    <div class="card-body p-0">
      <!--begin: Settings-->
      <div
        class="wizard wizard-1"
        id="kt_wizard_v1"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Settings Body-->
        <div class="row justify-content-center">
          <div class="col-xl-12 col-xxl-8 invoicepage">
            <el-form ref="form" :model="form" auto-complete="nope">
              <!--begin: Wizard Step 1-->
              <div
                class="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <h3 class="mb-10 font-weight-bold text-dark" v-if="title_text">
                  <i aria-hidden="true" class="fa fa-credit-card"></i>
                  {{ title_text }}
                </h3>
                <div class="row">
                  <div class="col-sm-12">
                    <template>
                      <div class="row">
                        <div class="col-md-12">
                          <h2 class="credit-username">
                            <label><i class="flaticon2-user"></i></label>
                            {{ user_name }}
                            <span style="font-size: 18px"
                              >(<b class="active"
                                >Total Credit : $
                                {{
                                  credit.approved_total_credit
                                    ? parseFloat(
                                        credit.approved_total_credit
                                      ).toFixed(2)
                                    : "0.00"
                                }}</b
                              >)</span
                            >
                          </h2>
                          <h5 class="credit-username">
                            <label><i class="flaticon-suitcase"></i></label>
                            <span> Credit Term </span>
                            <span>
                              (<b class="active">{{
                                credit_term
                                  ? credit_term.term_name + " days"
                                  : "Nil"
                              }}</b
                              >)</span
                            >
                            <el-tag size="mini" style="margin-left: 10px">
                              <a href="#" @click.prevent="ChangeTerm()"
                                >Change</a
                              >
                            </el-tag>
                          </h5>
                          <div class="row">
                            <div class="col-sm-4">
                              <template>
                                <div class="credit-container">
                                  <el-divider content-position="left"
                                    >Request Credit</el-divider
                                  >
                                  <h2 class="text-center">
                                    ${{
                                      credit.credit_limit
                                        ? parseFloat(
                                            credit.credit_limit
                                          ).toFixed(2)
                                        : 0.0
                                    }}
                                  </h2>
                                </div>
                              </template>
                            </div>
                            <div class="col-sm-4">
                              <template>
                                <div class="credit-container">
                                  <el-divider content-position="left"
                                    >Available Credit</el-divider
                                  >
                                  <h2 class="text-center">
                                    ${{
                                      credit.available_credit
                                        ? parseFloat(
                                            credit.available_credit
                                          ).toFixed(2)
                                        : 0.0
                                    }}
                                  </h2>
                                </div>
                              </template>
                            </div>
                            <div class="col-sm-4">
                              <template>
                                <div class="credit-container">
                                  <el-divider content-position="left"
                                    >Balance Credit</el-divider
                                  >
                                  <h2 class="text-center">
                                    ${{
                                      credit.balance_showing
                                        ? parseFloat(
                                            credit.balance_showing
                                          ).toFixed(2)
                                        : 0.0
                                    }}
                                  </h2>
                                </div>
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-6 mb-5" style="text_align: center">
                        <div
                          class="col-md-3 ml-5"
                          v-for="(document, index) in document_name"
                          :key="index"
                        >
                          <el-image
                            style="width: 150px; height: 200px"
                            :src="document.image"
                            :preview-src-list="document.src"
                          >
                          </el-image>
                          <div class="mt-3 document_name">
                            {{ document.name }}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <h5>
                            <b>Verified Document</b
                            ><el-tag size="mini" style="margin-left: 10px"
                              ><a
                                href="#"
                                @click.prevent="createNewRequiredDocument"
                                >Add Document</a
                              ></el-tag
                            >
                          </h5>
                          <el-form-item prop="document_list">
                            <el-checkbox-group v-model="form.document_list">
                              required_credit_documents
                              <el-checkbox
                                v-for="required_credit_document in required_credit_documents"
                                :key="required_credit_document.id"
                                :checked="
                                  checkSelected(
                                    required_credit_document.document_name
                                  )
                                "
                                :label="required_credit_document.document_name"
                                :value="required_credit_document.id"
                                >{{ required_credit_document.document_name }}
                                <span
                                  @click="
                                    editVerifiedDoc(
                                      required_credit_document.id,
                                      required_credit_document.document_name
                                    )
                                  "
                                  class="el-icon-edit active"
                                ></span>
                                <span
                                  @click="
                                    deleteVerifiedDoc(
                                      required_credit_document.id,
                                      required_credit_document.document_name
                                    )
                                  "
                                  class="el-icon-delete red"
                                ></span
                              ></el-checkbox>
                            </el-checkbox-group>
                          </el-form-item>
                          <span
                            class="form-err"
                            v-if="form.errors.has('document_list')"
                            >Verified Document is required</span
                          >
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-3">
                          <el-form-item
                            label="Approved amount"
                            prop="approved_credit"
                          >
                            <el-input
                              prefix-icon="fas fa-dollar-sign"
                              v-model="form.approved_credit"
                              @change="checkAmount(form.approved_credit)"
                            ></el-input>
                          </el-form-item>
                          <span
                            class="form-err"
                            v-if="form.errors.has('approved_credit')"
                            >{{ form.errors.get("approved_credit") }}</span
                          >
                        </div>
                        <div class="col-sm-3">
                          <el-form-item label="Verified By" prop="verified_by">
                            <el-input
                              v-model="form.verified_by"
                              @input.native="capitalize"
                            ></el-input>
                          </el-form-item>
                          <span
                            class="form-err"
                            v-if="form.errors.has('verified_by')"
                            >Verified field is required</span
                          >
                        </div>
                      </div>
                    </template>
                    <!-- <p>Update Status if Credit is approved</p> -->
                    <div v-if="credit.status != 'Rejected'" class="mt-9">
                      <b-button
                        v-if="credit.status != 'Approved'"
                        class="mr-3"
                        variant="primary"
                        @click="updateCredit('Approved')"
                        >Approve</b-button
                      >
                      <b-button
                        v-if="credit.status != 'Approved'"
                        variant="danger"
                        @click="rejectCredit()"
                        >Reject</b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="Credit Reject Confirmation"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span><b>Cancel Reason</b></span>
      <el-input
        type="textarea"
        minRows="2"
        v-model="form.reject_reason"
      ></el-input>
      <span class="form-err" v-if="form.errors.has('reject_reason')"
        >Reject reason field is required</span
      >
      <span class="mt-5"><b>Reject by</b></span>
      <el-input v-model="form.verified_by" style="with: 50%"></el-input>
      <span class="form-err" v-if="form.errors.has('verified_by')"
        >Reject by field is required</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button type="warning" @click="dialogVisible = false"
          >Cancel</el-button
        >
        <el-button type="primary" @click="updateCredit('Rejected')"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>


<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from "@/core/services/api.form.services";
import {
  getCreditView,
  getCreditRequiredlist,
  deleteCreditVerifiedDoc,
} from "@/api/customer";
import CreateDocument from "@/components/CreateCustomer/document_type";
import CreateRequiredDocument from "@/components/CreateCustomer/required_document";
import { globalEvents } from "@/utils/globalEvents";
import CreditTerm from "@/components/CreateCustomer/credit_term.vue";
export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      credit: [],
      user_name: null,
      img_url: "https://stagingurl.tk/ctshub_api/public/images/credit/",
      url1: "",
      url2: "",
      url3: "",
      url4: "",
      srcList1: [],
      srcList2: [],
      srcList3: [],
      srcList4: [],
      required_credit_documents: [],
      form: new Form({
        status: null,
        document_list: [],
        verified_by: null,
        approved_credit: null,
        customer_id: null,
        reject_reason: null,
      }),
      title_text: "Approve Credit",
      document_name: [],
      credit_term: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Credits", route: "/customer/credit" },
      { title: "Customer Credits" },
    ]);
    globalEvents.$on("RequiredDocument", (doc, id) => {
      if (doc && !id) {
        this.required_credit_documents.push(doc);
      }
      if (id && doc) {
        for (var reqdoc in this.required_credit_documents) {
          if (this.required_credit_documents[reqdoc].id == id)
            this.required_credit_documents[reqdoc].document_name =
              doc.document_name;
        }
      }
    });

    globalEvents.$on("creditTerm", (added) => {
      this.credit_term = [];
      this.credit_term = added.credit_term;
      console.log(added);
    });
  },
  methods: {
    getCreditview() {
      this.loading = true;
      getCreditView(this.$route.params.credit_id).then((response) => {
        this.credit = response.data.data;
        this.credit_term = response.data.data.get_customer.credit_term;
        this.form.customer_id = response.data.data.customer_id;
        if (this.credit.get_customer.last_name) {
          this.user_name =
            this.credit.get_customer.first_name +
            " " +
            this.credit.get_customer.last_name;
        } else this.user_name = this.credit.get_customer.first_name;
        this.srcList1.push(
          process.env.VUE_APP_BASE_IMAGE_URL +
            "/credit/" +
            response.data.data.document_license
        );
        this.url1 =
          process.env.VUE_APP_BASE_IMAGE_URL +
          "credit/" +
          response.data.data.document_license;
        this.srcList2.push(
          process.env.VUE_APP_BASE_IMAGE_URL +
            "/credit/" +
            response.data.data.document_passport
        );
        this.url2 =
          process.env.VUE_APP_BASE_IMAGE_URL +
          "/credit/" +
          response.data.data.document_passport;
        this.srcList3.push(
          process.env.VUE_APP_BASE_IMAGE_URL +
            "/credit/" +
            response.data.data.document_health
        );
        this.url3 =
          process.env.VUE_APP_BASE_IMAGE_URL +
          "/credit/" +
          response.data.data.document_health;
        this.srcList4.push(
          process.env.VUE_APP_BASE_IMAGE_URL +
            "/credit/" +
            response.data.data.document_other
        );
        this.url4 =
          process.env.VUE_APP_BASE_IMAGE_URL +
          "/credit/" +
          response.data.data.document_other;
        this.form.refered_by = this.credit.refered_by;
        this.form.available_credit = this.credit.available_credit;
        if (response.data.data.document_name) {
          let document = response.data.data.document_name.split(",");
          for (var i = 0; i < document.length; i++) {
            if (document[i] == "Driving license") {
              this.document_name.push({
                name: document[i],
                image: this.url1,
                src: this.srcList1,
              });
            }
            if (document[i] == "Passport") {
              this.document_name.push({
                name: document[i],
                image: this.url2,
                src: this.srcList2,
              });
            }
            if (document[i] == "Health Card") {
              this.document_name.push({
                name: document[i],
                image: this.url3,
                src: this.srcList3,
              });
            }
            if (document[i] == "Others") {
              this.document_name.push({
                name: document[i],
                image: this.url4,
                src: this.srcList4,
              });
            }
          }
        }

        this.loading = false;
      });
    },
    checkAmount(amount) {
      this.form.errors.add("approved_credit", " ");
      let due_amount = parseFloat(amount)
        .toFixed(2)
        .match(/^-?[0-9]*[.][0-9]+$/);
      if (this.credit.credit_limit >= due_amount) {
        if (due_amount != null) {
          this.form.approved_credit = parseFloat(due_amount).toFixed(2);
        } else {
          this.form.approved_credit = "";
          this.form.errors.add("approved_credit", "Please Enter Amount Only");
        }
      } else {
        this.form.approved_credit = "";
        this.form.errors.add("approved_credit", "Please Enter Max Amount Only");
      }
    },
    editVerifiedDoc(id, name) {
      this.$modal.show(
        CreateRequiredDocument,
        {
          label: {
            button: "Edit",
            title: "Update Verified Document",
          },
          id: id,
          name: name,
        },
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
    deleteVerifiedDoc(id, name) {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to delete " + name + " ?", {
          title: "Please Confirm",
          size: "md",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            deleteCreditVerifiedDoc(id).then((response) => {
              if (response.data.status) {
                for (var reqdoc in this.required_credit_documents) {
                  if (this.required_credit_documents[reqdoc].id == id)
                    this.required_credit_documents.splice(reqdoc, 1);
                }
                this.$showResponse("success", response.data.message);
              }
              this.loading = false;
            });
          } else {
            return false;
          }
        });
    },
    getCreditRequiredlist() {
      getCreditRequiredlist().then((response) => {
        this.required_credit_documents = response.data.data;
      });
    },
    CreateDocument() {
      this.$modal.show(
        CreateDocument,
        {},
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
    rejectCredit() {
      this.dialogVisible = true;
    },
    updateCredit(status) {
      this.loading = true;
      this.form.status = status;
      this.api_url = "customer/credit/" + this.$route.params.credit_id;
      this.form
        .post(this.api_url)
        .then((response) => {
          if (response.status) {
            //globalEvents.$emit('CreditAdded', response.data)
            this.$emit("close");
            this.$router.push({ path: "/customer/credit/" });
            this.loading = false;
            if (response.data.status == "Approved") {
              this.$showResponse("success", response.message);
            } else {
              this.$showResponse("success", "Rejected  Customer Credit");
            }
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    createNewRequiredDocument() {
      this.$modal.show(
        CreateRequiredDocument,
        {
          label: {
            button: "Add",
            title: "Add Verified Document",
          },
        },
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
    ChangeTerm() {
      this.$modal.show(
        CreditTerm,
        {
          customer_id: this.form.customer_id,
        },
        {
          draggable: true,
          transition: "pop-out",
          overflow: "auto",
          height: "auto",
          clickToClose: false,
          adaptive: true,
          width: "600",
        }
      );
    },
    checkSelected(name) {
      if (!this.credit.document_list) return false;
      else {
        var document_list = this.credit.document_list.split(",");
        if (document_list.includes(name)) return true;
        else return false;
      }
    },
  },
  created() {
    this.getCreditview();
    this.getCreditRequiredlist();
  },
};
</script>

<style scoped>
.review-layout {
  border-style: groove;
  padding: 40px;
  border-color: #366092;
}
.el-icon-circle-close {
  font-size: 40px;
}

.document_name {
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}
</style>
